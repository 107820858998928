import * as JSPM from "jsprintmanager";
import router from "../router/router";
import { SYSTEM_ERROR } from "../components/utility/js/constants-utility";
import EncryptUtility from "../components/utility/js/encrypt-utility";

import { store } from "../state-management/state-mgmt";

export default {
  padValue(value) {
    return value < 10 ? "0" + value : value;
  },
  convertESTToLocal: function (dateString) {
    /*
        - calculate the difference between the server date and UTC
        - the value returned by the getTime method is the number of milliseconds since 1 January 1970 00:00:00 UTC.
        - the time-zone offset is the difference, in minutes, between UTC and local time
        - 60000 milliseconds = 60 seconds = 1 minute
      */
    var serverDate = new Date(dateString);
    var utc = serverDate.getTime() - serverDate.getTimezoneOffset() * 60000;
    /*
        - apply the offset between UTC and EST (5 hours)
        - 3600000 milliseconds = 3600 seconds = 60 minutes = 1 hour
      */
    var date = new Date(utc);
    const milliseconds = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    var localTime = new Date(milliseconds);
    var newtime = localTime.toISOString().split("T")[1];
    //var datefor = localTime.getDate() // local date
    var hour = newtime.split(":")[0]; // local hour
    var Ishourcheck = parseInt(hour);
    var sAMPM = "AM";
    if (Ishourcheck > 12) {
      sAMPM = "PM";
      hour = Ishourcheck - 12;
      hour = this.padValue(hour);
    }
    if (Ishourcheck == 12) {
      sAMPM = "PM";
    }
    let locDate = localTime.toISOString().split("T")[0].split("-");
    var finaldate =
      locDate[1] +
      "/" +
      locDate[2] +
      "/" +
      locDate[0] +
      " " +
      hour +
      ":" +
      newtime.split(":")[1] +
      ":" +
      newtime.split(":")[2].split(".")[0] +
      " " +
      sAMPM;
    return finaldate;
  },
  convertESTToLocalOnlyDate: function (dateString) {
    var serverDate = new Date(dateString);
    var utc = serverDate.getTime() - serverDate.getTimezoneOffset() * 60000;
    var date = new Date(utc);
    const milliseconds = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    var localTime = new Date(milliseconds);
    let locDate = localTime.toISOString().split("T")[0].split("-");
    var finaldate = locDate[1] + "/" + locDate[2] + "/" + locDate[0];
    return finaldate;
  },
  convertESTToLocalDate: function (dateString) {
    var serverDate = new Date(dateString);
    let date = serverDate.toISOString().substr(0, 10);
    return date;
  },

  convertESTToLocalDateSO: function (dateString) {
    let date = dateString.split('T')[0]
    return date
  },
  getLocalTime: function () {
    let date = new Date();
    const milliseconds = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    var localTime = new Date(milliseconds);
    var newtime = localTime.toISOString().split("T")[1];
    //var datefor = localTime.getDate() // local date
    var hour = newtime.split(":")[0]; // local hour
    var Ishourcheck = parseInt(hour);
    var sAMPM = "AM";
    if (Ishourcheck > 12) {
      sAMPM = "PM";
      hour = Ishourcheck - 12;
      hour = this.padValue(hour);
    }
    if (Ishourcheck == 12) {
      sAMPM = "PM";
    }
    let locDate = localTime.toISOString().split("T")[0].split("-");
    var finaldate =
      locDate[1] +
      "/" +
      locDate[2] +
      "/" +
      locDate[0] +
      " " +
      hour +
      ":" +
      newtime.split(":")[1] +
      ":" +
      newtime.split(":")[2].split(".")[0] +
      " " +
      sAMPM;
    return finaldate;
  },
  //JS Print method
  printZPL(printerName, printString) {
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
      let cpj = new JSPM.ClientPrintJob();
      // var cookieName = "primaryName";
      // var theCookie = " " + document.cookie;
      // var ind = theCookie.indexOf(" " + cookieName + "=");
      // if (ind == -1) ind = theCookie.indexOf(";" + cookieName + "=");
      // if (ind == -1 || cookieName == "") return "";
      // var ind1 = theCookie.indexOf(";", ind + 1);
      // if (ind1 == -1) ind1 = theCookie.length;
      // var printerval = unescape(theCookie.substring(ind + cookieName.length + 2, ind1));
      cpj.clientPrinter = new JSPM.InstalledPrinter(printerName);
      //sample ZPL -- > DO NOT REMOVE THE COMMENT - KS
      // var cmds = "^XA";
      // cmds += "^FO20,30^GB750,1100,4^FS";
      // cmds += "^FO20,30^GB750,200,4^FS";
      // cmds += "^FO20,30^GB750,400,4^FS";
      // cmds += "^FO20,30^GB750,700,4^FS";
      // cmds += "^FO20,226^GB325,204,4^FS";
      // cmds += "^FO30,40^ADN,36,20^FDShip to:^FS";
      // cmds += "^FO30,260^ADN,18,10^FDPart number #^FS";
      // cmds += "^FO360,260^ADN,18,10^FDDescription:^FS";
      // cmds += "^FO30,750^ADN,36,20^FDFrom:^FS";
      // cmds += "^FO150,125^ADN,36,20^FDAcme Printing^FS";
      // cmds += "^FO60,330^ADN,36,20^FD14042^FS";
      // cmds += "^FO400,330^ADN,36,20^FDScrew^FS";
      // cmds += "^FO70,480^BY4^B3N,,200^FD12345678^FS";
      // cmds += "^FO150,800^ADN,36,20^FDMacks Fabricating^FS";
      // cmds += "^XZ";
      //printString.replace(/\r\n/g, "") //print this in log
      cpj.printerCommands = printString.replace(/\r\n/g, "");
      cpj.sendToClient();
    }
  },
  //JS Print method with an array
  printZPLMultiple(printerName, printArray) {
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
      let cpj = new JSPM.ClientPrintJob();
      // var cookieName = "primaryName";
      // var theCookie = " " + document.cookie;
      // var ind = theCookie.indexOf(" " + cookieName + "=");
      // if (ind == -1) ind = theCookie.indexOf(";" + cookieName + "=");
      // if (ind == -1 || cookieName == "") return "";
      // var ind1 = theCookie.indexOf(";", ind + 1);
      // if (ind1 == -1) ind1 = theCookie.length;
      // var printerval = unescape(theCookie.substring(ind + cookieName.length + 2, ind1));
      cpj.clientPrinter = new JSPM.InstalledPrinter(printerName);
      printArray.forEach((element) => {
        cpj.printerCommands = element.lpn_barcode.replace(/\r\n/g, "");
        cpj.sendToClient();
      });
    }
  },
  //Print PDF
  printZPLPDF(printPDF, printerName) {
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
      let cpj = new JSPM.ClientPrintJob();
      // var cookieName = "primaryName";
      // var theCookie = " " + document.cookie;
      // var ind = theCookie.indexOf(" " + cookieName + "=");
      // if (ind == -1) ind = theCookie.indexOf(";" + cookieName + "=");
      // if (ind == -1 || cookieName == "") return "";
      // var ind1 = theCookie.indexOf(";", ind + 1);
      // if (ind1 == -1) ind1 = theCookie.length;
      // var printerval = unescape(theCookie.substring(ind + cookieName.length + 2, ind1));
      // cpj.clientPrinter = new JSPM.InstalledPrinter(printerval);
      //let pdfName = "Print_PDF" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
      cpj.clientPrinter = new JSPM.InstalledPrinter(printerName);
      var my_file = new JSPM.PrintFilePDF(printPDF, JSPM.FileSourceType.Base64, "MyFile.pdf", 1);
      cpj.files.push(my_file);
      cpj.sendToClient();
    }
    var index = 0;
    JSPM.JSPrintManager.WS.onStatusChanged = function () {
      if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
        let cpj = new JSPM.ClientPrintJob();
        // var cookieName = "primaryName";
        // var theCookie = " " + document.cookie;
        // var ind = theCookie.indexOf(" " + cookieName + "=");
        // if (ind == -1) ind = theCookie.indexOf(";" + cookieName + "=");
        // if (ind == -1 || cookieName == "") return "";
        // var ind1 = theCookie.indexOf(";", ind + 1);
        // if (ind1 == -1) ind1 = theCookie.length;
        // var printerval = unescape(theCookie.substring(ind + cookieName.length + 2, ind1));
        // cpj.clientPrinter = new JSPM.InstalledPrinter(printerval);
        //let pdfName = "Print_PDF" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
        cpj.clientPrinter = new JSPM.InstalledPrinter(printerName);
        var my_file = new JSPM.PrintFilePDF(printPDF, JSPM.FileSourceType.Base64, "MyFile.pdf", 1);
        cpj.files.push(my_file);
        cpj.sendToClient();
      } else {
        if (JSPM.JSPrintManager.websocket_status != JSPM.WSStatus.WaitingForUserResponse && index == 0) {
          alert(
            "Printer utility is not installed, please download the Printer utility from page menu and install. If it has already been downloaded, please open it. It is not running in the background."
          );
          index++;
        }
      }
    };
  },
  //Logging out the user
  logOut: function () {
    localStorage.clear();
    sessionStorage.clear();
    EncryptUtility.localStorageEncrypt("showNav", false.toString());
    router.push("/login");
    window.location.reload();
  },
  // API error
  apiError(error) {
    var errMsg = SYSTEM_ERROR;
    var isError = false;
    if (error !== undefined && error !== null) {
      if (error.response !== undefined && error.response !== null) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (error.response.data.error != undefined && error.response.data.error !== null && error.response.data.error.length > 0) {
            errMsg = error.response.data.error[0];
            isError = true;
          }
          if (!isError && error.response.data.message !== undefined && error.response.data.message !== null) {
            errMsg = error.response.data.message;
          }
        }
      }
    }
    return errMsg;
  },
  // Cnvert input date to UTC Date
  convertLocalToUTC(date) {
    //Check if date is empty or not
    if (this.isNonIsoDate(date) || !date) {
      //Default Date selected
      date = new Date();
      date = new Date(
        Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
      ).toISOString();
      return date;
    } else {
      //User selcted Date
      date = new Date(date);
      let localTime = new Date();
      date = new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          localTime.getUTCHours(),
          localTime.getUTCMinutes(),
          localTime.getUTCSeconds()
        )
      ).toISOString();
      return date;
    }
  },
  //To check if the date is in ISO format or not
  isNonIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str);
    return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
  },
  getMonthDetails(month, year) {
    let monthData = [
      {
        month: "January",
        startDate: "01/01",
        endDate: "01/02",
        NumDays: 31,
      },
      {
        month: "February",
        startDate: "01/02",
        endDate: "01/03",
        NumDays: this.leapYear(year) ? 29 : 28,
      },
      {
        month: "March",
        startDate: "01/03",
        endDate: "01/04",
        NumDays: 31,
      },
      {
        month: "April",
        startDate: "01/04",
        endDate: "01/05",
        NumDays: 30,
      },

      {
        month: "May",
        startDate: "01/05",
        endDate: "01/06",
        NumDays: 31,
      },
      {
        month: "June",
        startDate: "01/06",
        endDate: "01/07",
        NumDays: 30,
      },
      {
        month: "July",
        startDate: "01/07",
        endDate: "01/08",
        NumDays: 31,
      },
      {
        month: "August",
        startDate: "01/08",
        endDate: "01/09",
        NumDays: 31,
      },
      {
        month: "September",
        startDate: "01/09",
        endDate: "01/10",
        NumDays: 30,
      },
      {
        month: "October",
        startDate: "01/10",
        endDate: "01/11",
        NumDays: 31,
      },
      {
        month: "November",
        startDate: "01/11",
        endDate: "01/12",
        NumDays: 30,
      },
      {
        month: "December",
        startDate: "01/12",
        endDate: "01/01" + 1,
        NumDays: 31,
      },
    ];
    let monthDetails = monthData.filter((x) => x.month == month);
    return monthDetails;
  },
  leapYear(year) {
    return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
  },
  getAllAtTop(items, key) {
    const allItem = items.find((item) => item[key].trim() === "All");
    const others = items.filter((item) => item[key].trim() !== "All");
    const sortedOthers = others.sort((a, b) => a[key].localeCompare(b[key]));
    return allItem ? [allItem, ...sortedOthers] : sortedOthers;
  },

  debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  },

  insertLineBreaks(values, number, delimiter, showComma) {
    // Return the string as is if the delimiter is empty
    if (typeof values === "string" && delimiter === "") {
      return values;
    }
    // Check if values is a string and contains the delimiter
    if (typeof values === "string") {
      if (values.includes(delimiter)) {
        values = values.split(delimiter);
      } else {
        return values; // Return the string as is if no delimiter is found
      }
    }
    // If the input is not an array or the array is empty, return an empty string
    if (!Array.isArray(values) || values.length === 0) return "";
    // Insert <br> after every specified number of values
    return values
      .map((value, index) => {
        let formattedValue = value;
        // Append <br> if the specified number of values has been reached
        if ((index + 1) % number === 0) {
          formattedValue += "<br>";
        }
        return formattedValue;
      })
      .join(showComma ? ", " : " ")
      .replace(/<br>, /g, "<br>") // Removes comma after a line break
      .trim(); // Remove any leading/trailing whitespace
  },
  //Excel File naming
  excelFileName(name) {
    let xlsName = name + "_List_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    return xlsName;
  },
  //Check string is a JSON or not
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
  // No data availabe toast
  showNoDataToast(msg) {
    let Alert = {
      type: "error",
      isShow: true,
      message: msg,
    };
    store.commit("ConfigModule/Alert", Alert);
  },

  // to make user only eneter number with hypen
  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode < 48 || charCode > 57) {
      evt.preventDefault();
    } else {
      return true;
    }
  },

  // to make user only eneter number with hypen
  isNumberWithHypen(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 45) {
      evt.preventDefault();
    } else {
      return true;
    }
  },

  // to make user only eneter number with Period
  isNumberWithPeriod(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  },

  // if form is invalid it will scroll the invalid part into view
  validateForm(formRefName, context) {
    const formRef = context.$refs[formRefName];

    if (formRef && !formRef.validate()) {
      let invalidField = formRef.$children.find((e) => !e.valid);

      if (invalidField) {
        invalidField.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  },

  // action setter
  actionSetter(actionList, action) {
    if (actionList !== null && actionList.length > 0) {
      let filter = actionList.filter((entry) => entry.SystemName === action);
      return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
    } else return false;
  },

  formatLPNNumber(evt, num) {
    evt = evt || window.event;
    const charCode = evt.which || evt.keyCode;

    // Allow numeric characters and hyphen (-)
    if (
      (charCode > 31 && (charCode < 48 || charCode > 57)) &&
      charCode !== 45 // Allow hyphen
    ) {
      evt.preventDefault();
      return;
    }

    // Remove any non-numeric characters except hyphen
    let numbers = num.replace(/[^0-9]/g, ""); // Only keep numbers for formatting
    if (numbers.length === 0) {
      return ""; // Return empty if no numbers
    }
    let lastGroup = "";
    let remainingDigits = numbers;
    // Ensure the last group has 3 digits only if total length is 4 or more
    if (numbers.length > 2) {
      lastGroup = numbers.slice(-2); // Get the last 3 digits
      remainingDigits = numbers.slice(0, -2); // Remove the last 3 digits from the remaining
    }
    // First group logic - the first group is dynamic, 1 to 3 digits
    let firstGroupLength = remainingDigits.length % 3 || 3;
    let prefix = remainingDigits.slice(0, firstGroupLength);
    let middleDigits = remainingDigits.slice(firstGroupLength);
    // Format the middle digits into groups of three
    let groupedMiddle = middleDigits.match(/.{1,3}/g) || []; // Create groups of 3 digits
    // Combine the prefix, middle groups, and last group with hyphens
    let formatted = [prefix, ...groupedMiddle];
    if (lastGroup) {
      formatted.push(lastGroup); // Add the last group only if it exists
    }
    // Return the formatted number and preserve any manually typed hyphens
    return formatted.join("-").replace(/--+/g, "-"); // Prevent double hyphens
  },


  formatPostalCode(evt, num) {
    evt = evt || window.event;
    var charCode = evt.which || evt.keyCode;

    // Allow only numeric characters
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    } else {
      // Remove any non-numeric characters
      let numbers = num.replace(/[^0-9]/g, "");
      if (numbers.length === 0) {
        return "";
      }
      let prefix = numbers.slice(0, 5);
      numbers = numbers.slice(5); // Remaining digits

      // Format the remaining digits in groups of three
      let formatted = prefix;
      if (numbers.length > 0) {
        formatted += "-";
      }
      for (let i = 0; i < numbers.length; i += 5) {
        formatted += numbers.slice(i, i + 5);
        if (i + 5 < numbers.length) {
          formatted += "-"; // Add a hyphen between groups of three
        }
      }
      return formatted;
    }
  },
};
